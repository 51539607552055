import CreateCompanyDialog from "@/components/companies/company/CreateCompanyDialog.vue";
import EditCompanyDialog from "@/components/companies/company/EditCompanyDialog.vue";
import DeleteCompanyDialog from "@/components/companies/company/DeleteCompanyDialog.vue";
import SearchInput from "@/components/global/SearchInput.vue";

export default {
  name: "Companies",

  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "name",
        align: "start",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Sociedad",
        value: "society",
        sortable: false,
        class: "primary--text",
      },
      {
        text: "Estado",
        value: "active",
        sortable: false,
        align: "center",
        class: "primary--text",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "right",
        class: "primary--text",
      },
    ],    
    editedItem: {},
    deletedItem: {},
    currentPage: 1,
    search: "",
  }),
  computed: {
    companies() {
      return this.$store.getters["companies/getCompanies"];
    },
    isLoading() {
      return this.$store.state.companies.isLoading;
    },
    paginationPages() {
      return this.$store.getters["companies/getPaginationPages"];
    },
  },

  watch: {},

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch("companies/getCompanies", { page: 1 });
      this.$store.dispatch("companies/updateCurrentPage", 1);
    },

    editItem(item) {
      this.editedItem = { ...item };
    },

    deleteItem(item) {
      this.deletedItem = { ...item };
    },

    listenPagination() {
      this.$store.dispatch("companies/getCompanies", {
        page: this.currentPage,
        search: this.search,
      });
      this.$store.dispatch("companies/updateCurrentPage", this.currentPage);
    },

    resetFilter() {
      this.search = "";
      this.currentPage = 1;
    },
  },
  components: {
    CreateCompanyDialog,
    EditCompanyDialog,
    DeleteCompanyDialog,
    SearchInput,
  },
};
