<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-body-1 primary--text justify-center">
        ¿Estás seguro de que quieres eliminar?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="deleteItemConfirm()">Si</v-btn>
        <v-btn color="blue" text @click="closeDelete()">NO</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from "@/main";
export default {
  name: "DeleteCompanyDialog",
  props: {
    company: Object,
  },
  data: () => ({
    dialogDelete: false,
    isLoading: false,
  }),
  watch: {
    company(val) {
      if (val && val.id) {
        this.dialogDelete = true;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async deleteItemConfirm() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("companies/deleteCompany", this.company.id);
        $EventBus.$emit("showSnack", {
          color: "green",
          text: "Empresa eliminada",
        });
        this.$emit("resetFilter");
      } catch (error) {
        $EventBus.$emit("showSnack", {
          color: "red",
          text: error,
        });
      }
      this.isLoading = false;
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {});
    },
  },
};
</script>
